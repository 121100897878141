$(() => {
  // The following handler sets the "remember me"-parameter for the login urls
  const $rememberMeCheckbox = $('#remember-me');
  $rememberMeCheckbox.on('change', () => {
    $('.login-url').each(function() {
      const $element = $(this);
      const attr = $element.is('form') ? 'action' : 'href';
      const url = new URL($element.attr(attr) ?? '', document.location.href);
      const rememberMe = $rememberMeCheckbox.is(':checked');
      url.searchParams.set('_remember_me', rememberMe ? '1' : '0');
      $element.attr(attr, url.href);

      document.dispatchEvent(new CustomEvent('auth_remember_me', {detail: rememberMe}));
    });
  });
});
